import * as React from "react";
import { Container } from "reactstrap";
import cityNetwork from "../../images/city-network.jpg";

const HomeHero: React.FunctionComponent = () => (
    <div
        className="d-flex flex-column justify-content-center align-items-center text-center bg-secondary text-white"
        style={{
            minHeight: "70vh",
            backgroundImage: `url(${cityNetwork})`,
            backgroundPosition: "top center",
            backgroundSize: "cover"
        }}
    >
        <h1 className="display-3">Tiber Creek Consulting</h1>
        <h2 className="font-weight-normal">
            Secure Solutions for a Data-Driven World
        </h2>
    </div>
);

export default HomeHero;
