import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import HomeHero from "../components/home/hero";
import { Card, CardBody, CardText, Container } from "reactstrap";
import ContentSection from "../components/contentSection";
import Capabilities from "../components/home/capabilities";
import Testimonials from "../components/home/testimonials";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />

        <HomeHero />

        <ContentSection title="Services Tailored to You">
            <p>
                From start to finish, we stand by you to provide individualized
                solutions to your IT needs. Together, we will create an
                effective and strategic plan of action to deliver a customized,
                innovative solution. Promptly, you will receive scalable,
                cutting-edge technological solutions. Take advantage of our
                services today.
            </p>
        </ContentSection>

        <ContentSection
            backgroundColor="dark"
            textColor="white"
            title="We Work Hard So You Can Work Smart"
        >
            <p className="mb-5">
                Achieve your organizational goals with IT that automates your
                business processes and keeps your information safe. Our highly
                qualified and experienced team demonstrates excellence in
                providing solutions to your IT needs. At Tiber Creek, we serve
                to transform your data into information and empower you with the
                knowledge to make informed decisions with confidence.
            </p>

            <Capabilities />
        </ContentSection>

        <ContentSection title="Proudly Serving our Federal Government and Military">
            <p className="mb-4">
                Tiber Creek was founded as an information technology outsourcing
                company in support of the Department of Defense. As a
                woman-owned small business, we help federal agencies meet their
                missions with advanced, modern IT solutions. Since 1992, we have
                proudly provided superior results for our government’s IT needs.
            </p>
        </ContentSection>

        <ContentSection backgroundColor="secondary" textColor="white">
            <div className="text-center">
                <Link
                    to="/contract-vehicles"
                    className="btn btn-primary btn-lg"
                >
                    View our contract vehicles
                </Link>
            </div>
        </ContentSection>

        <ContentSection title="Testimonials">
            <Testimonials />
        </ContentSection>

        <ContentSection title="Learn More About Us">
            <p>
                Whether your business needs custom application development, big
                data analytics, business process reengineering support, or a
                contract teaming partner, Tiber Creek’s team of experts is ready
                to help. Find out how we can improve your business.
            </p>

            <div className="text-center my-4">
                <Link className="btn btn-lg btn-primary" to="/contact">
                    Contact Us
                </Link>
            </div>
        </ContentSection>
    </Layout>
);

export default IndexPage;
