import * as React from "react";
import { Card, CardBody } from "reactstrap";
import { Testimonial } from "../../models";

interface TestimonialBlockProps {
    testimonial: Testimonial;
}

const TestimonialBlock: React.FunctionComponent<TestimonialBlockProps> = ({
    testimonial: { text, name, source }
}) => (
    <Card className="h-100" color="light">
        <CardBody className="h-100 d-flex flex-column justify-content-center align-items-start">
            <blockquote className="blockquote mb-0">
                <p className={name || source ? "mb-4" : "mb-0"}>{text}</p>

                {(name || source) && (
                    <footer className="blockquote-footer">
                        {name && name}
                        {name && source && ", "}
                        {source && source}
                    </footer>
                )}
            </blockquote>
        </CardBody>
    </Card>
);

export default TestimonialBlock;
