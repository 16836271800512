import * as React from "react";
import { Col, Row } from "reactstrap";
import { Testimonial } from "../../models";
import TestimonialBlock from "./testimonialBlock";

const testimonials: Testimonial[] = [
    {
        text:
            "Thank you all for your continuing beautiful work. We are getting more and more positive comments from our users, and they don’t even know yet how good it is going to be when the 2.x series are fully functional.",
        name: "Lynn M. Britt, CPI",
        source: "JD Britt & Company. P.A."
    },
    {
        text:
            "Tiber Creek is top notch: they are not only skilled, they are also people-oriented and don’t mind teaching us so we can manage on our own—no matter what the problem or question is, they give answers freely and are always in the background. They’ve saved my sanity, and our PR is great in the schools… there is real security in knowing that they will steer you in the right direction. If I walked out of here today, I know that Transylvania County School kids would be served—Tiber Creek is that outstanding.",
        name: "Sonys-Kaye Merrill",
        source: "CTO Transylvania County Schools"
    }
];

const Testimonials: React.FunctionComponent = () => (
    <Row>
        {testimonials.map((testimonial, i) => (
            <Col lg={6} key={`testimonial-${i}`} className="my-3">
                <TestimonialBlock testimonial={testimonial} />
            </Col>
        ))}
    </Row>
);

export default Testimonials;
